// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SingleResearchResultView_bookmarkWrapper__JvIYz svg {
  fill: #b7b7b7;
}

.SingleResearchResultView_bookmarkWrapper__JvIYz.SingleResearchResultView_on__GOQVb svg {
  fill: var(--accent);
}

.SingleResearchResultView_star__6wcwR polygon {
  fill: var(--accent);
}

.SingleResearchResultView_resultBox__jpfle {
  background-color: #ffffff;
  border: 1px solid #b7b7b7;
  border-radius: 24px;
  padding: 32px;
  width: 100%;
  height: 240px;
  overflow: hidden;
  transition: all 0.1s ease-out;
}

.SingleResearchResultView_resultBox__jpfle:hover {
  cursor: pointer;
  transform: translate3d(0px, -4px, 0px);
  box-shadow: 0px 16px 32px #00000022;
}

.SingleResearchResultView_borderBottom__ob3nI {
  border-bottom: 1px solid #b7b7b7;
}

@media (prefers-color-scheme: dark) {
  .SingleResearchResultView_resultBox__jpfle {
    background-color: #111111;
    border: 1px solid #595959;
    border-radius: 32px;
    width: 100%;
  }

  .SingleResearchResultView_resultBox__jpfle:hover {
    cursor: pointer;
    transform: translate3d(0px, -4px, 0px);
    border: 1px solid #dc143c66;
    box-shadow: 0px 0px 8px #dc143c66;
  }

  .SingleResearchResultView_bookmarkWrapper__JvIYz svg {
    fill: #595959;
  }

  .SingleResearchResultView_borderBottom__ob3nI {
    border-bottom: 1px solid #595959;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Search/SingleResearchResultView.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,6BAA6B;AAC/B;;AAEA;EACE,eAAe;EACf,sCAAsC;EACtC,mCAAmC;AACrC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE;IACE,yBAAyB;IACzB,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,eAAe;IACf,sCAAsC;IACtC,2BAA2B;IAC3B,iCAAiC;EACnC;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,gCAAgC;EAClC;AACF","sourcesContent":[".bookmarkWrapper svg {\n  fill: #b7b7b7;\n}\n\n.bookmarkWrapper.on svg {\n  fill: var(--accent);\n}\n\n.star polygon {\n  fill: var(--accent);\n}\n\n.resultBox {\n  background-color: #ffffff;\n  border: 1px solid #b7b7b7;\n  border-radius: 24px;\n  padding: 32px;\n  width: 100%;\n  height: 240px;\n  overflow: hidden;\n  transition: all 0.1s ease-out;\n}\n\n.resultBox:hover {\n  cursor: pointer;\n  transform: translate3d(0px, -4px, 0px);\n  box-shadow: 0px 16px 32px #00000022;\n}\n\n.borderBottom {\n  border-bottom: 1px solid #b7b7b7;\n}\n\n@media (prefers-color-scheme: dark) {\n  .resultBox {\n    background-color: #111111;\n    border: 1px solid #595959;\n    border-radius: 32px;\n    width: 100%;\n  }\n\n  .resultBox:hover {\n    cursor: pointer;\n    transform: translate3d(0px, -4px, 0px);\n    border: 1px solid #dc143c66;\n    box-shadow: 0px 0px 8px #dc143c66;\n  }\n\n  .bookmarkWrapper svg {\n    fill: #595959;\n  }\n\n  .borderBottom {\n    border-bottom: 1px solid #595959;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bookmarkWrapper": `SingleResearchResultView_bookmarkWrapper__JvIYz`,
	"on": `SingleResearchResultView_on__GOQVb`,
	"star": `SingleResearchResultView_star__6wcwR`,
	"resultBox": `SingleResearchResultView_resultBox__jpfle`,
	"borderBottom": `SingleResearchResultView_borderBottom__ob3nI`
};
export default ___CSS_LOADER_EXPORT___;
