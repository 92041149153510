// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Lectures_container__gmnVV {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
}

.Lectures_searchForm__pzM5\\+ {
  width: min(70%, 800px);
}

@media (max-width: 720px) {
  .Lectures_searchForm__pzM5\\+ {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Search/Lectures.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,4DAA4D;EAC5D,6BAA6B;EAC7B,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".container {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));\n  justify-content: space-evenly;\n  align-items: center;\n  gap: 20px;\n}\n\n.searchForm {\n  width: min(70%, 800px);\n}\n\n@media (max-width: 720px) {\n  .searchForm {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Lectures_container__gmnVV`,
	"searchForm": `Lectures_searchForm__pzM5+`
};
export default ___CSS_LOADER_EXPORT___;
