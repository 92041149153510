// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextField_text-field-container__s94cV {
  position: relative;
}

/* .text-field-input:focus {
  outline: none;
  border: 1px solid var(--accent);
  box-shadow: 0px 0px 4px var(--accent);
} */

.TextField_text-field-input__L6E57 {
  background-color: var(--back-0);
  color: var(--fore-0);
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 8px;
  
  border: 1px solid #b7b7b7;
  transition: all .1s ease;
}

.TextField_text-field-input__L6E57.TextField_valid__ETGGA {
  border-color: #1bbe66;
}

.TextField_text-field-input__L6E57.TextField_invalid__-DtTQ {
  border-color: var(--accent);
}

.TextField_error-message__4EHDg {
  color: var(--accent);
  font-size: 12px;
  margin-top: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/base/TextField.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;;;;GAIG;;AAEH;EACE,+BAA+B;EAC/B,oBAAoB;EACpB,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;;EAElB,yBAAyB;EACzB,wBAAwB;AAC1B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,oBAAoB;EACpB,eAAe;EACf,eAAe;AACjB","sourcesContent":[".text-field-container {\n  position: relative;\n}\n\n/* .text-field-input:focus {\n  outline: none;\n  border: 1px solid var(--accent);\n  box-shadow: 0px 0px 4px var(--accent);\n} */\n\n.text-field-input {\n  background-color: var(--back-0);\n  color: var(--fore-0);\n  width: 100%;\n  padding: 8px;\n  box-sizing: border-box;\n  border-radius: 8px;\n  \n  border: 1px solid #b7b7b7;\n  transition: all .1s ease;\n}\n\n.text-field-input.valid {\n  border-color: #1bbe66;\n}\n\n.text-field-input.invalid {\n  border-color: var(--accent);\n}\n\n.error-message {\n  color: var(--accent);\n  font-size: 12px;\n  margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text-field-container": `TextField_text-field-container__s94cV`,
	"text-field-input": `TextField_text-field-input__L6E57`,
	"valid": `TextField_valid__ETGGA`,
	"invalid": `TextField_invalid__-DtTQ`,
	"error-message": `TextField_error-message__4EHDg`
};
export default ___CSS_LOADER_EXPORT___;
