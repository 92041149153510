// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modallike_container__WOMRY {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh;
  transition: all 0.3s ease;
  background-color: #eeeeee;
}

.Modallike_modal__jKNVJ {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 48px;
  border: solid #dddddd 1px;
  background-color: #ffffff; /* Change the color as needed */
  transition: all 0.3s ease;
  box-shadow: 0px 20px 20px #00000033;
  overflow: hidden;
}

@media (prefers-color-scheme: dark) {
  .Modallike_container__WOMRY {
    background-color: #111111;
  }

  .Modallike_modal__jKNVJ {
    border: solid #333333 1px;
    background-color: #222222; /* Change the color as needed */
  }
}

@media (max-width: 720px) {
  .Modallike_modal__jKNVJ {
    width: 100% !important;
    height: 100% !important;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Modallike/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB,EAAE,wBAAwB;EACjD,mBAAmB,EAAE,sBAAsB;EAC3C,aAAa;EACb,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,yBAAyB,EAAE,+BAA+B;EAC1D,yBAAyB;EACzB,mCAAmC;EACnC,gBAAgB;AAClB;;AAEA;EACE;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,yBAAyB,EAAE,+BAA+B;EAC5D;AACF;;AAEA;EACE;IACE,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;IACvB,uBAAuB;IACvB,eAAe;IACf,cAAc;EAChB;AACF","sourcesContent":[".container {\n  display: flex;\n  justify-content: center; /* Center horizontally */\n  align-items: center; /* Center vertically */\n  height: 100vh;\n  transition: all 0.3s ease;\n  background-color: #eeeeee;\n}\n\n.modal {\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n  border-radius: 48px;\n  border: solid #dddddd 1px;\n  background-color: #ffffff; /* Change the color as needed */\n  transition: all 0.3s ease;\n  box-shadow: 0px 20px 20px #00000033;\n  overflow: hidden;\n}\n\n@media (prefers-color-scheme: dark) {\n  .container {\n    background-color: #111111;\n  }\n\n  .modal {\n    border: solid #333333 1px;\n    background-color: #222222; /* Change the color as needed */\n  }\n}\n\n@media (max-width: 720px) {\n  .modal {\n    width: 100% !important;\n    height: 100% !important;\n    justify-content: center;\n    align-items: flex-start;\n    flex-wrap: wrap;\n    overflow: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Modallike_container__WOMRY`,
	"modal": `Modallike_modal__jKNVJ`
};
export default ___CSS_LOADER_EXPORT___;
