// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.A_red__foeST {
  color: #dc143c;
  color: var(--accent);
  text-decoration: none;
  transition: all 0.1s ease;
}
.A_red__foeST:hover {
  text-decoration: underline;
}

.A_monotone__HtHZZ {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease;
}
.A_monotone__HtHZZ:hover {
  filter: brightness(1.2);
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/base/A.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,qBAAqB;EACrB,yBAAyB;AAC3B;AACA;EACE,0BAA0B;AAC5B;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,yBAAyB;AAC3B;AACA;EACE,uBAAuB;EACvB,0BAA0B;AAC5B","sourcesContent":[".red {\n  color: #dc143c;\n  color: var(--accent);\n  text-decoration: none;\n  transition: all 0.1s ease;\n}\n.red:hover {\n  text-decoration: underline;\n}\n\n.monotone {\n  color: inherit;\n  text-decoration: none;\n  transition: all 0.1s ease;\n}\n.monotone:hover {\n  filter: brightness(1.2);\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"red": `A_red__foeST`,
	"monotone": `A_monotone__HtHZZ`
};
export default ___CSS_LOADER_EXPORT___;
