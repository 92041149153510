// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchForm_searchBox__BgSL9 {
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  border: 1px solid #b7b7b7;
  border-radius: 20px;
  transition: all 0.2s ease;
  /* width: minmax(100%, 1fr); */
}

.SearchForm_searchBox__BgSL9:focus-within {
  box-shadow: 0px 8px 16px #00000055;
}

.SearchForm_textarea__wPExk {
  width: 100%;
}

.SearchForm_textarea__wPExk:focus {
  outline: none;
}

.SearchForm_textarea__wPExk:focus {
  outline: none;
}

@media (prefers-color-scheme: dark) {
  .SearchForm_searchBox__BgSL9 {
    background-color: #0b0b0b0b;
    border: 1px solid #595959;
  }

  .SearchForm_searchBox__BgSL9:focus-within {
    box-shadow: 0px 8px 16px #000000;
  }

  .SearchForm_textarea__wPExk:focus {
    outline: none;
  }

  .SearchForm_textarea__wPExk {
    color: white;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/composite/SearchForm.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,yBAAyB;EACzB,mBAAmB;EACnB,yBAAyB;EACzB,8BAA8B;AAChC;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,2BAA2B;IAC3B,yBAAyB;EAC3B;;EAEA;IACE,gCAAgC;EAClC;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".searchBox {\n  padding: 0px 20px;\n  justify-content: center;\n  align-items: center;\n  background-color: #f5f5f5;\n  border: 1px solid #b7b7b7;\n  border-radius: 20px;\n  transition: all 0.2s ease;\n  /* width: minmax(100%, 1fr); */\n}\n\n.searchBox:focus-within {\n  box-shadow: 0px 8px 16px #00000055;\n}\n\n.textarea {\n  width: 100%;\n}\n\n.textarea:focus {\n  outline: none;\n}\n\n.textarea:focus {\n  outline: none;\n}\n\n@media (prefers-color-scheme: dark) {\n  .searchBox {\n    background-color: #0b0b0b0b;\n    border: 1px solid #595959;\n  }\n\n  .searchBox:focus-within {\n    box-shadow: 0px 8px 16px #000000;\n  }\n\n  .textarea:focus {\n    outline: none;\n  }\n\n  .textarea {\n    color: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchBox": `SearchForm_searchBox__BgSL9`,
	"textarea": `SearchForm_textarea__wPExk`
};
export default ___CSS_LOADER_EXPORT___;
