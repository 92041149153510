// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input {
  /* margin: 20px; */
  padding: 10px;
  background-color: white;
  accent-color: red;
  border-radius: 8px;
  border: 1px solid #b7b7b7;
  transition: all 0.2s ease;
}

input:focus {
  border: solid red 1px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Input/index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["input {\n  /* margin: 20px; */\n  padding: 10px;\n  background-color: white;\n  accent-color: red;\n  border-radius: 8px;\n  border: 1px solid #b7b7b7;\n  transition: all 0.2s ease;\n}\n\ninput:focus {\n  border: solid red 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
