// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdaptiveStack_main__4ByX6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: 'inherit'
}

@media ( max-width: 720px ) {
  .AdaptiveStack_main__4ByX6 {
    flex-direction: column;
    align-items: start;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/base/AdaptiveStack/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB;AACF;;AAEA;EACE;IACE,sBAAsB;IACtB,kBAAkB;EACpB;AACF","sourcesContent":[".main {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  color: 'inherit'\n}\n\n@media ( max-width: 720px ) {\n  .main {\n    flex-direction: column;\n    align-items: start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `AdaptiveStack_main__4ByX6`
};
export default ___CSS_LOADER_EXPORT___;
