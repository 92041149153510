// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainContainer_metacontainer__MkAj3 {
    padding-left: 40px;
    padding-right: 40px;
  }
  
  .MainContainer_metacontainerMargin__FW1ns {
    margin-left: 40px;
    margin-right: 40px;
  }
  
  @media (max-width: 720px) {
    .MainContainer_metacontainer__MkAj3 {
      padding-left: 20px;
      padding-right: 20px;
    }
    .MainContainer_metacontainerMargin__FW1ns {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/composite/MainContainer.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE;MACE,kBAAkB;MAClB,mBAAmB;IACrB;IACA;MACE,iBAAiB;MACjB,kBAAkB;IACpB;EACF","sourcesContent":[".metacontainer {\n    padding-left: 40px;\n    padding-right: 40px;\n  }\n  \n  .metacontainerMargin {\n    margin-left: 40px;\n    margin-right: 40px;\n  }\n  \n  @media (max-width: 720px) {\n    .metacontainer {\n      padding-left: 20px;\n      padding-right: 20px;\n    }\n    .metacontainerMargin {\n      margin-left: 20px;\n      margin-right: 20px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"metacontainer": `MainContainer_metacontainer__MkAj3`,
	"metacontainerMargin": `MainContainer_metacontainerMargin__FW1ns`
};
export default ___CSS_LOADER_EXPORT___;
