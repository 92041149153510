// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Main_searchForm__PZ26z {
  width: min(60%, 800px);
}

@media (max-width: 720px) {
  .Main_searchForm__PZ26z {
    width: 80%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Main.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".searchForm {\n  width: min(60%, 800px);\n}\n\n@media (max-width: 720px) {\n  .searchForm {\n    width: 80%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchForm": `Main_searchForm__PZ26z`
};
export default ___CSS_LOADER_EXPORT___;
