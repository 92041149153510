// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  position: relative;
  /* bottom: 0; */
  width: 100%;
  font-size: small;
  /* height: 2.5rem;            Footer height */
}

.Footer_padding__utxbc {
  border-top: solid 1px hsl(0, 0%, 86%);
  padding: 40px 20px;
}

@media (prefers-color-scheme: dark) {
  .Footer_padding__utxbc {
    border-top: 1px solid #424242;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/composite/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,6CAA6C;AAC/C;;AAEA;EACE,qCAAqC;EACrC,kBAAkB;AACpB;;AAEA;EACE;IACE,6BAA6B;EAC/B;AACF","sourcesContent":["footer {\n  position: relative;\n  /* bottom: 0; */\n  width: 100%;\n  font-size: small;\n  /* height: 2.5rem;            Footer height */\n}\n\n.padding {\n  border-top: solid 1px hsl(0, 0%, 86%);\n  padding: 40px 20px;\n}\n\n@media (prefers-color-scheme: dark) {\n  .padding {\n    border-top: 1px solid #424242;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"padding": `Footer_padding__utxbc`
};
export default ___CSS_LOADER_EXPORT___;
