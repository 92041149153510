// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popover_popover__3ZHfh {
  position: absolute;
  top: 64px;
  right: 40px;
  border: 1px solid #dbdbdb;
  border-radius: 18px;
  background-color: #ffffff;
  overflow:hidden;
  box-shadow: 0px 10px 20px #00000044;
}

.popover_popover__3ZHfh > * {
  background: none !important;
  color: black !important;
}

@media (max-width: 720px) {
  .popover_popover__3ZHfh {
    right: 20px;
  }
}

@media (prefers-color-scheme: dark)  {
  .popover_popover__3ZHfh {
    border: 1px solid #353535;
    background-color: #212121;
  }

  .popover_popover__3ZHfh > * {
    color: white !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Topnav/popover.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,yBAAyB;EACzB,mBAAmB;EACnB,yBAAyB;EACzB,eAAe;EACf,mCAAmC;AACrC;;AAEA;EACE,2BAA2B;EAC3B,uBAAuB;AACzB;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,yBAAyB;IACzB,yBAAyB;EAC3B;;EAEA;IACE,uBAAuB;EACzB;AACF","sourcesContent":[".popover {\n  position: absolute;\n  top: 64px;\n  right: 40px;\n  border: 1px solid #dbdbdb;\n  border-radius: 18px;\n  background-color: #ffffff;\n  overflow:hidden;\n  box-shadow: 0px 10px 20px #00000044;\n}\n\n.popover > * {\n  background: none !important;\n  color: black !important;\n}\n\n@media (max-width: 720px) {\n  .popover {\n    right: 20px;\n  }\n}\n\n@media (prefers-color-scheme: dark)  {\n  .popover {\n    border: 1px solid #353535;\n    background-color: #212121;\n  }\n\n  .popover > * {\n    color: white !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popover": `popover_popover__3ZHfh`
};
export default ___CSS_LOADER_EXPORT___;
