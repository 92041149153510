// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Progress_back__NLojB {
  background-color: #dbdbdb;
  width: 100%;
  border-radius: 4px;
  transition: all .3s ease;
}

.Progress_front__caDdR {
  height: 4px;
  border-radius: 4px;
  transition: all .3s ease;
}

@media (prefers-color-scheme: dark) {
  .Progress_back__NLojB {
    background-color: #dbdbdb;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Progress/index.module.css"],"names":[],"mappings":";AACA;EACE,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;EACE;IACE,yBAAyB;EAC3B;AACF","sourcesContent":["\n.back {\n  background-color: #dbdbdb;\n  width: 100%;\n  border-radius: 4px;\n  transition: all .3s ease;\n}\n\n.front {\n  height: 4px;\n  border-radius: 4px;\n  transition: all .3s ease;\n}\n\n@media (prefers-color-scheme: dark) {\n  .back {\n    background-color: #dbdbdb;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"back": `Progress_back__NLojB`,
	"front": `Progress_front__caDdR`
};
export default ___CSS_LOADER_EXPORT___;
