// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Button_blank__XWuIs:hover {
  opacity: 0.5;
}

.Button_enabled__Ii2Cy.Button_outline__defCu:focus {
  box-shadow: 0px 4px 8px rgb(from #dc143c r g b / 0.5);
}

.Button_enabled__Ii2Cy.Button_outline__defCu:hover {
  box-shadow: 0px 4px 8px rgb(from #dc143c r g b / 0.5);
}

.Button_enabled__Ii2Cy.Button_outline__defCu {
  color: var(--accent);
  border: solid 1px var(--accent);
  background: rgb(from var(--accent) r g b / 0.01);
}

.Button_disabled__uHPaT.Button_outline__defCu {
  color: #dbdbdb;
  border: solid 1px #dbdbdb;
  background: #dbdbdb05;
}

.Button_enabled__Ii2Cy.Button_filled__CAUQD:hover {
  box-shadow: 0px 2px 4px var(--accent);
  /* background-color: hsl(from var(--accent) h calc(s * 1.2) calc(l * 1.2)); */
}

/* background-color: hsl(from var(--accent) h calc(s * 1.2) calc(l * 1.2)); */

.Button_enabled__Ii2Cy.Button_filled__CAUQD {
  color: white;
  border: none;
  background: var(--accent);
}

.Button_disabled__uHPaT.Button_filled__CAUQD {
  background: #dbdbdb;
}

.Button_disabled__uHPaT {
  cursor: not-allowed;
}

.Button_fullWidth__SfZPP {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/base/Button/index.module.css"],"names":[],"mappings":";AACA;EACE,YAAY;AACd;;AAEA;EACE,qDAAqD;AACvD;;AAEA;EACE,qDAAqD;AACvD;;AAEA;EACE,oBAAoB;EACpB,+BAA+B;EAC/B,gDAAgD;AAClD;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,qCAAqC;EACrC,6EAA6E;AAC/E;;AAEA,6EAA6E;;AAE7E;EACE,YAAY;EACZ,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb","sourcesContent":["\n.blank:hover {\n  opacity: 0.5;\n}\n\n.enabled.outline:focus {\n  box-shadow: 0px 4px 8px rgb(from #dc143c r g b / 0.5);\n}\n\n.enabled.outline:hover {\n  box-shadow: 0px 4px 8px rgb(from #dc143c r g b / 0.5);\n}\n\n.enabled.outline {\n  color: var(--accent);\n  border: solid 1px var(--accent);\n  background: rgb(from var(--accent) r g b / 0.01);\n}\n\n.disabled.outline {\n  color: #dbdbdb;\n  border: solid 1px #dbdbdb;\n  background: #dbdbdb05;\n}\n\n.enabled.filled:hover {\n  box-shadow: 0px 2px 4px var(--accent);\n  /* background-color: hsl(from var(--accent) h calc(s * 1.2) calc(l * 1.2)); */\n}\n\n/* background-color: hsl(from var(--accent) h calc(s * 1.2) calc(l * 1.2)); */\n\n.enabled.filled {\n  color: white;\n  border: none;\n  background: var(--accent);\n}\n\n.disabled.filled {\n  background: #dbdbdb;\n}\n\n.disabled {\n  cursor: not-allowed;\n}\n\n.fullWidth {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blank": `Button_blank__XWuIs`,
	"enabled": `Button_enabled__Ii2Cy`,
	"outline": `Button_outline__defCu`,
	"disabled": `Button_disabled__uHPaT`,
	"filled": `Button_filled__CAUQD`,
	"fullWidth": `Button_fullWidth__SfZPP`
};
export default ___CSS_LOADER_EXPORT___;
