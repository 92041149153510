// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.common_results__9v0AO {
  background-color: #f5f5f5;
  padding-top: 40px;
  padding-bottom: 40px;
  flex-grow: 1;
}

.common_borderBottom__ZXdSE {
  border-bottom: 1px solid #d4d4d4;
}

@media (prefers-color-scheme: dark) {
  .common_results__9v0AO {
    background-color: #0b0b0b;
  }

  .common_borderBottom__ZXdSE {
    border-bottom: 1px solid #3c3c3c;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Search/common.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,oBAAoB;EACpB,YAAY;AACd;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE;IACE,yBAAyB;EAC3B;;EAEA;IACE,gCAAgC;EAClC;AACF","sourcesContent":[".results {\n  background-color: #f5f5f5;\n  padding-top: 40px;\n  padding-bottom: 40px;\n  flex-grow: 1;\n}\n\n.borderBottom {\n  border-bottom: 1px solid #d4d4d4;\n}\n\n@media (prefers-color-scheme: dark) {\n  .results {\n    background-color: #0b0b0b;\n  }\n\n  .borderBottom {\n    border-bottom: 1px solid #3c3c3c;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"results": `common_results__9v0AO`,
	"borderBottom": `common_borderBottom__ZXdSE`
};
export default ___CSS_LOADER_EXPORT___;
