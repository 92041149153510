// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.common_borderBottom__OlCdW {
  border-bottom: 1px solid #d4d4d4;
}

@media (prefers-color-scheme: dark) {
  .common_borderBottom__OlCdW {
    border-bottom: 1px solid #3c3c3c;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Lecture/common.module.css"],"names":[],"mappings":";;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE;IACE,gCAAgC;EAClC;AACF","sourcesContent":["\n\n.borderBottom {\n  border-bottom: 1px solid #d4d4d4;\n}\n\n@media (prefers-color-scheme: dark) {\n  .borderBottom {\n    border-bottom: 1px solid #3c3c3c;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"borderBottom": `common_borderBottom__OlCdW`
};
export default ___CSS_LOADER_EXPORT___;
