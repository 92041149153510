// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `svg.icons_fill__oaz\\+6  {
  stroke: none;
}

svg.icons_line__o1KES {
  stroke: black;
}


@media (prefers-color-scheme: dark) {
  svg.icons_line__o1KES  {
    stroke: white;
  }
}
`, "",{"version":3,"sources":["webpack://./src/icons/index.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;;AAGA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":["svg.fill  {\n  stroke: none;\n}\n\nsvg.line {\n  stroke: black;\n}\n\n\n@media (prefers-color-scheme: dark) {\n  svg.line  {\n    stroke: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fill": `icons_fill__oaz+6`,
	"line": `icons_line__o1KES`
};
export default ___CSS_LOADER_EXPORT___;
