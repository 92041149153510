// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  --accent: #dc143c;
  box-sizing: border-box;
  /* user-select: none; */
  font-size: inherit;
}

*:hover {
  --accent: #e65a76;
}

*:hover:focus {
  --accent: #a51330;
}

:root {
  --back-0: #ffffff;
  --fore-0: #000000;
  --f5f5f5: #f5f5f5;
  --d4d4d4: #d4d4d4;
  --dbdbdb: #dbdbdb;
  --353535: #353535;
  --9b9b9b: #9b9b9b;

}

@media (prefers-color-scheme: dark) {
  :root {
    --back-0: #181818;
    --fore-0: #ffffff;
    --f5f5f5: #0b0b0b;
    --d4d4d4: #3c3c3c;
  --dbdbdb: #353535;
  --353535: #dbdbdb;
  --9b9b9b: #757575;
  }
}

html,
body {
  background-color: var(--back-0);
  color: var(--fore-0);
  height: 100%;
  margin: 0;
  accent-color: var(--back-0);
  word-break: keep-all;
}

body {
  display: flex;
  flex-direction: column;
}

/* 
.content-wrap {
    flex: 1;
} */

a {
  text-decoration: none;
  color: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;;AAEnB;;AAEA;EACE;IACE,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;EACnB,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB;AACF;;AAEA;;EAEE,+BAA+B;EAC/B,oBAAoB;EACpB,YAAY;EACZ,SAAS;EACT,2BAA2B;EAC3B,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;;;GAGG;;AAEH;EACE,qBAAqB;EACrB,cAAc;AAChB","sourcesContent":["* {\n  --accent: #dc143c;\n  box-sizing: border-box;\n  /* user-select: none; */\n  font-size: inherit;\n}\n\n*:hover {\n  --accent: #e65a76;\n}\n\n*:hover:focus {\n  --accent: #a51330;\n}\n\n:root {\n  --back-0: #ffffff;\n  --fore-0: #000000;\n  --f5f5f5: #f5f5f5;\n  --d4d4d4: #d4d4d4;\n  --dbdbdb: #dbdbdb;\n  --353535: #353535;\n  --9b9b9b: #9b9b9b;\n\n}\n\n@media (prefers-color-scheme: dark) {\n  :root {\n    --back-0: #181818;\n    --fore-0: #ffffff;\n    --f5f5f5: #0b0b0b;\n    --d4d4d4: #3c3c3c;\n  --dbdbdb: #353535;\n  --353535: #dbdbdb;\n  --9b9b9b: #757575;\n  }\n}\n\nhtml,\nbody {\n  background-color: var(--back-0);\n  color: var(--fore-0);\n  height: 100%;\n  margin: 0;\n  accent-color: var(--back-0);\n  word-break: keep-all;\n}\n\nbody {\n  display: flex;\n  flex-direction: column;\n}\n\n/* \n.content-wrap {\n    flex: 1;\n} */\n\na {\n  text-decoration: none;\n  color: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
