// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 이렇게 글꼴을 웹폰트로 다운받으면, 로컬 컴퓨터에 해당 글꼴이 없더라도 자동으로 다운받아서 적용합니다.(물론 그만큼 성능에는 안 좋습니다.) */
@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  font-family:
    'Pretendard-Regular',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
  font-family:
    'Pretendard-Regular',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto';
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,mFAAmF;AACnF;EACE,iCAAiC;EACjC,gHAAgH;EAChH,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT;;;;;;;;;;;;cAYY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,+EAA+E;AACjF;;AAEA;EACE,sBAAsB;EACtB;;;;;YAKU;AACZ","sourcesContent":["/* 이렇게 글꼴을 웹폰트로 다운받으면, 로컬 컴퓨터에 해당 글꼴이 없더라도 자동으로 다운받아서 적용합니다.(물론 그만큼 성능에는 안 좋습니다.) */\n@font-face {\n  font-family: 'Pretendard-Regular';\n  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');\n  font-weight: 400;\n  font-style: normal;\n}\n\nbody {\n  margin: 0;\n  font-family:\n    'Pretendard-Regular',\n    -apple-system,\n    BlinkMacSystemFont,\n    'Segoe UI',\n    'Roboto',\n    'Oxygen',\n    'Ubuntu',\n    'Cantarell',\n    'Fira Sans',\n    'Droid Sans',\n    'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;\n}\n\n* {\n  box-sizing: border-box;\n  font-family:\n    'Pretendard-Regular',\n    -apple-system,\n    BlinkMacSystemFont,\n    'Segoe UI',\n    'Roboto';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
