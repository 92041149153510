// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_shared__yCc0F {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  border: none; /* Example border - change color as needed */
  cursor: pointer; /* Changes cursor to pointer to indicate it's clickable */
  font-size: medium;
  transition: opacity 0.2s ease; /* Smooth transition for hover effect */
  opacity: 1;
  padding: 10px; /* Adjust padding as needed */
}

.button_shared__yCc0F:focus {
  opacity: 0.5;
}

.button_shared__yCc0F:hover {
  opacity: 0.5;
}

.button_selected__aqBDP {
  color: #dc143c;
}

@media (prefers-color-scheme: dark) {
  .button_unselected__dyOqk.button_pill__dUSIf {
    color: white;
    stroke: white;
  }

  .button_pill__dUSIf {
    border-radius: 100ch; /* Makes the element round */
    background: none;
    border: 1px solid white;
    color: white;
  }
}

.button_unselected__dyOqk.button_primary__YFR0f {
  color: inherit;
  stroke: inherit;
}

.button_unselected__dyOqk.button_pill__dUSIf {
  color: white;
  stroke: white;
}

.button_pill__dUSIf {
  border-radius: 100ch; /* Makes the element round */
  background: none;
  border: 1px solid white;
  color: white;
}

.button_primary__YFR0f {
  background: none; /* Sets a transparent background */
}

.button_blocking__TErHG {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/components/Topnav/button.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;;EAEnB,YAAY,EAAE,4CAA4C;EAC1D,eAAe,EAAE,yDAAyD;EAC1E,iBAAiB;EACjB,6BAA6B,EAAE,uCAAuC;EACtE,UAAU;EACV,aAAa,EAAE,6BAA6B;AAC9C;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,oBAAoB,EAAE,4BAA4B;IAClD,gBAAgB;IAChB,uBAAuB;IACvB,YAAY;EACd;AACF;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,oBAAoB,EAAE,4BAA4B;EAClD,gBAAgB;EAChB,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,gBAAgB,EAAE,kCAAkC;AACtD;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".shared {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: row;\n\n  border: none; /* Example border - change color as needed */\n  cursor: pointer; /* Changes cursor to pointer to indicate it's clickable */\n  font-size: medium;\n  transition: opacity 0.2s ease; /* Smooth transition for hover effect */\n  opacity: 1;\n  padding: 10px; /* Adjust padding as needed */\n}\n\n.shared:focus {\n  opacity: 0.5;\n}\n\n.shared:hover {\n  opacity: 0.5;\n}\n\n.selected {\n  color: #dc143c;\n}\n\n@media (prefers-color-scheme: dark) {\n  .unselected.pill {\n    color: white;\n    stroke: white;\n  }\n\n  .pill {\n    border-radius: 100ch; /* Makes the element round */\n    background: none;\n    border: 1px solid white;\n    color: white;\n  }\n}\n\n.unselected.primary {\n  color: inherit;\n  stroke: inherit;\n}\n\n.unselected.pill {\n  color: white;\n  stroke: white;\n}\n\n.pill {\n  border-radius: 100ch; /* Makes the element round */\n  background: none;\n  border: 1px solid white;\n  color: white;\n}\n\n.primary {\n  background: none; /* Sets a transparent background */\n}\n\n.blocking {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shared": `button_shared__yCc0F`,
	"selected": `button_selected__aqBDP`,
	"unselected": `button_unselected__dyOqk`,
	"pill": `button_pill__dUSIf`,
	"primary": `button_primary__YFR0f`,
	"blocking": `button_blocking__TErHG`
};
export default ___CSS_LOADER_EXPORT___;
