// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 
.t1 *:not(.t2 *, .t3 *, .t4 *, .t5 *, .t6 *)  {
  font-size: 32px;
}

.t2 *:not(.t1 *, .t3 *, .t4 *, .t5 * , .t6 *)   {
  font-size: 28px;
}
 
.t3 *:not(.t1 *, .t2 *, .t4 *, .t5 *, .t6 *)  {
  font-size: 24px;
}

.t4 *:not(.t1 *, .t2 *, .t3 *, .t5 *, .t6 *) {
  font-size: 20px;
}

.t5 *:not(.t1 *, .t2 *, .t3 *, .t4 *, .t6 *) {
  font-size: 16px;
}

.t6 *:not(.t1 *, .t2 *, .t3 *, .t4 *, .t5 *) {
  font-size: 12px;
} */

.Typography_t-4__aJBf8 {
  font-size: 52px;
}

.Typography_t-3__5U21c {
  font-size: 48px;
}
.Typography_t-2__mGF\\+T {
  font-size: 44px;
}
.Typography_t-1__GifJm {
  font-size: 40px;
}


.Typography_t0__EhU\\+X {
  font-size: 36px;
}

.Typography_t1__ikowv {
  font-size: 32px;
}

.Typography_t2__Svzyb {
  font-size: 28px;
}
 
.Typography_t3__vDmO0  {
  font-size: 24px;
}

.Typography_t4__LL-Eb {
  font-size: 20px;
}

.Typography_t5__EVhpK {
  font-size: 16px;
}

.Typography_t6__KNYQ1 {
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/base/Typography.module.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;GAuBG;;AAEH;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;;;AAGA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["/* \n.t1 *:not(.t2 *, .t3 *, .t4 *, .t5 *, .t6 *)  {\n  font-size: 32px;\n}\n\n.t2 *:not(.t1 *, .t3 *, .t4 *, .t5 * , .t6 *)   {\n  font-size: 28px;\n}\n \n.t3 *:not(.t1 *, .t2 *, .t4 *, .t5 *, .t6 *)  {\n  font-size: 24px;\n}\n\n.t4 *:not(.t1 *, .t2 *, .t3 *, .t5 *, .t6 *) {\n  font-size: 20px;\n}\n\n.t5 *:not(.t1 *, .t2 *, .t3 *, .t4 *, .t6 *) {\n  font-size: 16px;\n}\n\n.t6 *:not(.t1 *, .t2 *, .t3 *, .t4 *, .t5 *) {\n  font-size: 12px;\n} */\n\n.t-4 {\n  font-size: 52px;\n}\n\n.t-3 {\n  font-size: 48px;\n}\n.t-2 {\n  font-size: 44px;\n}\n.t-1 {\n  font-size: 40px;\n}\n\n\n.t0 {\n  font-size: 36px;\n}\n\n.t1 {\n  font-size: 32px;\n}\n\n.t2 {\n  font-size: 28px;\n}\n \n.t3  {\n  font-size: 24px;\n}\n\n.t4 {\n  font-size: 20px;\n}\n\n.t5 {\n  font-size: 16px;\n}\n\n.t6 {\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"t-4": `Typography_t-4__aJBf8`,
	"t-3": `Typography_t-3__5U21c`,
	"t-2": `Typography_t-2__mGF+T`,
	"t-1": `Typography_t-1__GifJm`,
	"t0": `Typography_t0__EhU+X`,
	"t1": `Typography_t1__ikowv`,
	"t2": `Typography_t2__Svzyb`,
	"t3": `Typography_t3__vDmO0`,
	"t4": `Typography_t4__LL-Eb`,
	"t5": `Typography_t5__EVhpK`,
	"t6": `Typography_t6__KNYQ1`
};
export default ___CSS_LOADER_EXPORT___;
