// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Tag_tag__VBpvo {
  background-color: #f5f5f5;
  color: #7a7a7a;
  height: 24px;
  border-radius: 24px;
  transition: all .3s ease;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding-inline: 10px;
}

@media (prefers-color-scheme: dark) {
  .Tag_tag__VBpvo {
    background-color: #0b0b0b;
    color: #969696;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/base/Tag.module.css"],"names":[],"mappings":";AACA;EACE,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,mBAAmB;EACnB,wBAAwB;EACxB,mBAAmB;EACnB,kBAAkB;EAClB,uBAAuB;EACvB,oBAAoB;AACtB;;AAEA;EACE;IACE,yBAAyB;IACzB,cAAc;EAChB;AACF","sourcesContent":["\n.tag {\n  background-color: #f5f5f5;\n  color: #7a7a7a;\n  height: 24px;\n  border-radius: 24px;\n  transition: all .3s ease;\n  align-items: center;\n  text-align: center;\n  justify-content: center;\n  padding-inline: 10px;\n}\n\n@media (prefers-color-scheme: dark) {\n  .tag {\n    background-color: #0b0b0b;\n    color: #969696;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `Tag_tag__VBpvo`
};
export default ___CSS_LOADER_EXPORT___;
